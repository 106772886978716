import React from 'react'

import Layout from '../components/Layout/Layout'
import Container from '../components/Container/Container'
import Seo from '../components/Seo/Seo'

import Try from '../sections/Try/Try'

const AboutPage = () => {
  return (
    <Layout pageName="security">
      <Seo title="Security Overview" />
      <Container>
        <section className="data">
          <h1 className="heading data__heading">Ally Security overview</h1>
          <h2 className="heading data__heading-sm">
            File Systems and Communication
          </h2>
          <p>
            Ally is committed to protecting the security of your information and
            takes reasonable precautions to protect it. We use industry-standard
            encryption to protect your data in transit and while it is stored on
            our servers. This is commonly referred to as transport layer
            security (“TLS”) or secure socket layer (“SSL”) technology. However,
            internet data transmissions cannot be guaranteed to be 100% secure,
            and we cannot ensure the security of information during its
            transmission between you and us. Accordingly, you acknowledge that
            when you transport such information, you do so at your own risk.
          </p>
          <p>
            We protect your information in our systems using technical and
            administrative security measures to reduce the risks of loss,
            misuse, unauthorized access, disclosure and alteration. Some of the
            safeguards we use are firewalls and data encryption, physical access
            controls to our data centers, and information access authorization
            controls.
          </p>
          <p>
            If we learn of a system breach, we may attempt to notify you and
            provide information on protective steps, if available, through the
            email address that you have provided to us or by posting a notice on
            our website and/or via our Service and other communication
            platforms. Depending on where you live, you may have a legal right
            to receive such notices in writing.
          </p>

          <h2 className="heading data__heading-sm">Employee Access</h2>
          <p>
            No Ally staff will access your data unless required for support
            reasons. In cases where staff must access business metrics in order
            to perform support, we will get your explicit consent each time,
            except when responding to a critical security issue or suspected
            abuse.
          </p>
          <p>
            When working a support issue we do our best to respect your privacy
            as much as possible, we only access the minimum data needed to
            resolve your issue.
          </p>
          <p>
            Finally, it’s worth noting that Ally's staff is quite small,
            limiting the number of individuals who would provide you support.
          </p>

          <h2 className="heading data__heading-sm">Credit Card Safety</h2>
          <p>
            When you purchase a paid Ally subscription, your credit card data is
            not transmitted through nor stored on our systems. Instead, we
            depend on Stripe, a company dedicated to this task (and whom we’ll
            go ahead and make the assumption you’re familiar with…for obvious
            reasons). Stripe is certified to PCI Service Provider Level 1, the
            most stringent level of certification available. Stripe’s security
            information is available online.
          </p>

          <h2 className="heading data__heading-sm">Contact Us</h2>
          <p>
            Have a question or concern? Please email us at
            <a href="mailto:support@getally.com">support@getally.com</a>
          </p>
        </section>
        <Try />
      </Container>
    </Layout>
  )
}

export default AboutPage
